// eslint-disable-next-line import/prefer-default-export
export const earnings = {
  namespaced: true,
  state: {
    otherIncomes: [],
    expenses: [],
    transfers: [],
    categories:[],
  },
  mutations: {
    setTransfers(state, data) {
      state.transfers = data.transfers
    },
    setOtherIncomes(state, data) {
      state.otherIncomes = data.otherIncomes
    },
    addOtherIncome: (state, otherIncome) => {
      state.otherIncomes = [...state.otherIncomes, otherIncome]
    },
    updateOtherIncome: (state, otherIncome) => {
      state.otherIncomes = state.otherIncomes.map(datum => {
        if (datum.id === otherIncome.id) {
          return otherIncome
        }
        return datum
      })
    },
    removeOtherIncome: (state, id) => {
      state.otherIncomes = state.otherIncomes.filter(datum => datum.id !== id)
    },
    setExpenses(state, data) {
      state.expenses = data.expenses
    },
    addExpense: (state, expense) => {
      state.expenses = [...state.expenses, expense]
    },
    updateExpense: (state, expense) => {
      state.expenses = state.expenses.map(datum => {
        if (datum.id === expense.id) {
          return expense
        }
        return datum
      })
    },
    removeExpense: (state, id) => {
      state.expenses = state.expenses.filter(datum => datum.id !== id)
    },
    setCategories(state, data) {
      state.categories = data.categories
    },
    addCategory: (state, category) => {
      state.categories = [...state.categories, category]
    },
    updateCategory: (state, category) => {
      state.categories = state.categories.map(datum => {
        if (datum.id === category.id) {
          return category
        }
        return datum
      })
    },
    removeCategory: (state, id) => {
      state.categories = state.categories.filter(datum => datum.id !== id)
    },
  },
  getters: {
    otherIncome(state) {
      return state.otherIncomes
    },
    expenses(state) {
      return state.expenses
    },
    categories(state) {
      return state.categories
    },
    transfers(state) {
      return state.transfers
    },
  },

}
