import gql from 'graphql-tag'

export const LOGIN_M = gql`mutation ($email: String!,$password: String!,$remember_me: Boolean!) {
    login(email: $email,password: $password, remember_me: $remember_me) {
        user{
            id
            first_name
            last_name
            active
            email
            phone
            photo
        }
        school{
            name
            about
            alias
            new_alias
            date_established
            email
            logo
            receipt_size
            phone
            slogan
            chat_access
            id
            verified
            accept_online_payments
            modules
            apply_charges
            active_sms
            trial_ends
            creche_classes
            lng
            lat
        }
        current_academic_year{
            name
            term
            tag
            ay_term_id
        }
        role
        permissions{
            name
            resource
        }
        token{
            access_token
            refresh_token
        }
        multiple_accounts
    }
}`

export const LOGOUT_M = gql`mutation{logout}`

export const REGISTER_M = gql`mutation ($user: UserRegistrationInput!,$school: SchoolRegistrationInput!,$address: AddressInput!) {
    register(user: $user,school: $school,address: $address)
}`

export const RESET_M = gql`mutation ($email: String!) {
    reset(email: $email)
}`
export const REFRESH_TOKEN_M = gql`mutation ($refresh_token: String!) {
    refreshToken(refresh_token:$refresh_token) {
        access_token
        refresh_token
    }
}`
export const CREATE_CLASS_M = gql`  mutation ($input: SchoolClassInput!) {
    createClass(input: $input){
        id
        class_teacher_id
        name
        cover
        teacher_name
    }
}`
export const DELETE_CLASS_M = gql`mutation ($id: Int!) {
    deleteClass(id: $id){
        id
    }
}`

export const UPDATE_CLASS_M = gql`mutation ($input: SchoolClassInput!) {
    updateClass(input: $input){
        id
        cover
        name
        class_teacher_id
        teacher_name
    }
}`
export const CREATE_TERM_M = gql`mutation ($input: TermInput!) {
    createTerm(input: $input){
        id
        name
    }
}`

export const DELETE_TERM_M = gql`mutation ($id: Int!) {
    deleteTerm(id: $id){
        id
    }
}`

export const UPDATE_TERM_M = gql`mutation ($input: TermInput!) {
    updateTerm(input: $input){
        id
        name
    }
}`

export const CREATE_TAG_M = gql`mutation ($input: TagInput!) {
    createTag(input: $input){
        id
        name
    }
}`

export const DELETE_TAG_M = gql`mutation ($id: Int!) {
    deleteTag(id: $id){
        id
    }
}`

export const UPDATE_TAG_M = gql`mutation ($input: TagInput!) {
    updateTag(input: $input){
        id
        name
    }
}`

export const CREATE_FORM_M = gql`mutation ($input: ReportFormInput!) {
    createReportForm(input: $input){
        id
        title
        type
        class_ids
        questions
    }
}`

export const DELETE_FORM_M = gql`mutation ($id: Int!) {
    deleteReportForm(id: $id){
        id
        title
        type
        class_ids
        questions
    }
}`

export const UPDATE_FORM_M = gql`mutation ($input: ReportFormInput!) {
    updateReportForm(input: $input){
        id
        title
        type
        class_ids
        questions
    }
}`
export const CREATE_DEPARTMENT_M = gql`mutation ($input: DepartmentInput!) {
    createDepartment(input: $input){
        id
        title
    }
}`

export const DELETE_DEPARTMENT_M = gql`mutation ($id: Int!) {
    deleteDepartment(id: $id){
        id
    }
}`

export const UPDATE_DEPARTMENT_M = gql`mutation ($input: DepartmentInput!) {
    updateDepartment(input: $input){
        id
        title
    }
}`
export const CREATE_ALERT_M = gql`mutation ($input: AlertInput!) {
    createAlert(input: $input){
        id
        title
    }
}`

export const DELETE_ALERT_M = gql`mutation ($id: Int!) {
    deleteAlert(id: $id){
        id
    }
}`

export const UPDATE_ALERT_M = gql`mutation ($input: AlertInput!) {
    updateAlert(input: $input){
        id
        title
    }
}`
export const CREATE_LOCATION_M = gql`mutation ($input: LocationInput!) {
    createLocation(input: $input){
        id
        title
        latitude
        longitude
        staff_members
    }
}`

export const DELETE_LOCATION_M = gql`mutation ($id: Int!) {
    deleteLocation(id: $id){
        id
    }
}`

export const UPDATE_LOCATION_M = gql`mutation ($input: LocationInput!) {
    updateLocation(input: $input){
        id
        title
        latitude
        longitude
        staff_members
    }
}`

export const CREATE_BANK_M = gql`mutation ($input: BankInput!) {
    createBank(input: $input){
        id
        title
        account_name
        account_number
        bank_code
    }
}`

export const DELETE_BANK_M = gql`mutation ($id: Int!) {
    deleteBank(id: $id){
        id
    }
}`
export const UPDATE_BANK_M = gql`mutation ($input: BankInput!) {
    updateBank(input: $input){
        id
        title
        account_name
        account_number
        bank_code
    }
}`

export const CREATE_POSITION_M = gql`mutation ($input: PositionInput!) {
    createPosition(input: $input){
        id
        title
    }
}`

export const DELETE_POSITION_M = gql`mutation ($id: Int!) {
    deletePosition(id: $id){
        id
    }
}`
export const UPDATE_POSITION_M = gql`mutation ($input: PositionInput!) {
    updatePosition(input: $input){
        id
        title
    }
}`
export const SEND_OTP_M = gql`mutation ($password: String!) {
    sendOtp(password: $password)
}`

export const DISBURSE_SALARIES_M = gql`mutation ($input: DisbursementInput!) {
    disburseSalaries(input: $input)
}`

export const SEND_STAFF_NOTIFICATION_M = gql`mutation ($input: StaffNotificationInput!) {
    sendNotificationToStaff(input: $input)
}`

export const CREATE_STAFF_M = gql`mutation ($input: StaffInput!) {
    createStaff(input: $input){
        id
        staff_id
        user_id
        gender
        dob
        emergency_contact_name
        emergency_contact_phone
        address
        position_id
        department_id
        employment_type
        employment_start_date
        employment_end_date
        employment_end_reason
        ssnit
        bank_name
        bank_code
        bank_account_name
        bank_account_number
        base_salary
        pays_ssnit
        pays_tax
        first_name
        last_name
        photo
        phone
        email
        status
        department
        position
        age
    }
}`

export const DELETE_STAFF_M = gql`mutation ($id: Int!) {
    deleteStaff(id: $id){
        id
    }
}`

export const UPDATE_STAFF_M = gql`mutation ($input: StaffInput!) {
    updateStaff(input: $input){
        id
        staff_id
        user_id
        gender
        dob
        emergency_contact_name
        emergency_contact_phone
        address
        position_id
        department_id
        employment_type
        employment_start_date
        employment_end_date
        employment_end_reason
        ssnit
        bank_name
        bank_code
        bank_account_name
        bank_account_number
        base_salary
        pays_ssnit
        pays_tax
        first_name
        last_name
        photo
        phone
        email
        status
        department
        position
        age
    }
}`
export const DELETE_ACADEMIC_YEAR_TERM_M = gql` mutation ($id: Int!) {
    deleteYearTerm(id: $id){
        id
    }
}`
export const DELETE_ACADEMIC_YEAR_M = gql` mutation ($id: Int!) {
    deleteAcademicYear(id: $id)
}`
export const RESET_TO_CURRENT_AY_M = gql` mutation {
    resetToCurrentAy{
        custom
        name
        term
    }
}`

export const UPDATE_DEFAULT_ACADEMIC_YEAR_M = gql` mutation ($academic_year_id: Int!,$term_id: Int!,$users: [Int!]!) {
    updateDefaultAy(academic_year_id: $academic_year_id,term_id:$term_id,users: $users){
        default_academic_year{
            academic_year_id
            term_id
            users
            enabled
        }
        current_academic_year{
            name
            term
            custom
        }
    }
}`

export const SETUP_ACADEMIC_YEAR_M = gql`    mutation ($academic_year: AcademicYearInput!) {
    setupAcademicYear(academic_year: $academic_year){
        id
        current
        name
        start_date
        end_date
        formatted_start_date
        formatted_end_date
        most_recent
        terms{
            id
            term_id
            classes{
                id
                name
            }
            formatted_vacation_date
            formatted_next_term_reopening_date
            vacation_date
            next_term_reopening_date
            term_name
            midterms {
                title
                start_date
                end_date
            }
            holidays {
                title
                date
            }
            term_start_date
            term_end_date
            formatted_term_start_date
            formatted_term_end_date
            tag_id
            is_promotional_term
        }
    }
}`
export const CREATE_GRADE_M = gql`mutation ($input: GradeInput!) {
    createGrade(input: $input){
        id
        label
        value
        remark
    }
}`

export const DELETE_GRADE_M = gql`mutation ($id: Int!) {
    deleteGrade(id: $id){
        id
        label
        value
        remark
    }
}`

export const UPDATE_GRADE_M = gql`mutation ($input: GradeInput!) {
    updateGrade(input: $input){
        id
        label
        value
        remark
    }
}`
export const CREATE_GRADING_SYSTEM_M = gql`mutation ($grading_system: GradingSystemInput!) {
    setupGradingSystem(grading_system: $grading_system){
        id
        name
        classes{
            id
            name
            teacher_name
        }
        grades{
            id
            grade_id
            label
            min_score
            max_score
            remark
        }
    }
}`

export const DELETE_GRADING_SYSTEM_GRADE_M = gql`mutation ($id: Int!) {
    deleteGradingSystemGrade(id: $id){
        id
    }
}`

export const DELETE_GRADING_SYSTEM_M = gql`mutation ($id: Int!) {
    deleteGradingSystem(id: $id){
        id
    }
}`

export const UPDATE_GRADING_SYSTEM_M = gql`mutation ($grading_system: GradingSystemInput!) {
    setupGradingSystem(grading_system: $grading_system){
        id
        name
        classes{
            id
            name
            teacher_name
        }
        grades{
            id
            grade_id
            label
            min_score
            max_score
            remark
        }
    }
}`

export const CREATE_SUBJECT_M = gql`mutation ($input: SubjectInput!) {
    createSubject(input: $input){
        id
        name
        tag
    }
}`

export const DELETE_SUBJECT_M = gql`mutation ($id: Int!) {
    deleteSubject(id: $id){
        id
        name
        tag
    }
}`

export const UPDATE_SUBJECT_M = gql`mutation ($input: SubjectInput!) {
    updateSubject(input: $input){
        id
        name
        tag
    }
}`
export const CREATE_AGGREGATE_M = gql`mutation ($input: GradingInput!) {
    createGrading(input: $input){
        id
        title
        best_core_count
        core_subjects
        best_elective_count
        elective_subjects
        classes
        exclude_subjects
    }
}`

export const DELETE_AGGREGATE_M = gql`mutation ($id: Int!) {
    deleteGrading(id: $id){
        id
    }
}`

export const UPDATE_AGGREGATE_M = gql`mutation ($input: GradingInput!) {
    updateGrading(input: $input){
        id
        title
        best_core_count
        core_subjects
        best_elective_count
        elective_subjects
        classes
        exclude_subjects
    }
}`
export const UPDATE_PROMOTION_FLOW_M = gql`mutation ($input: [PromotionFlowInput!]!) {
    updatePromotionFlow(flow: $input)
}`
export const UPDATE_CLASS_POSITIONS_M = gql`mutation ($input: [ClassPositionInput!]!) {
    updateClassPositions(positions: $input)
}`

export const CREATE_PROMOTION_M = gql`mutation ($input: PromotionInput!) {
    createPromotion(input: $input){
        id
        title
        absence_rate
        min_cum_average
        max_cum_average
        consider_improvement
        improvement_rate
        classes
    }
}`

export const DELETE_PROMOTION_M = gql`mutation ($id: Int!) {
    deletePromotion(id: $id){
        id
    }
}`

export const UPDATE_PROMOTION_M = gql`mutation ($input: PromotionInput!) {
    updatePromotion(input: $input){
        id
        title
        absence_rate
        min_cum_average
        max_cum_average
        consider_improvement
        improvement_rate
        classes
    }
}`

export const UPDATE_CUSTOMIZATION_M = gql`mutation ($input: CustomizationInput!) {
    updateCustomization(input: $input){
        id
        type
        document_version
        customization{
            show_logo
            show_student_id
            show_emergency_phone
            show_class
            show_dob
            is_gradient
            first_color
            second_color
            bg_color
            alt_bg_color
            text_color
            issued_date_text_color
            barcode_color
        }
    }
}`

export const CREATE_REMARK_M = gql`mutation ($input: RemarkInput!) {
    createRemark(input: $input){
        id
        title
        remarks{
            comment
            min_average
            max_average
            advanced
            alternative_remarks{
                improving
                dis_improving
            }
        }
        classes
    }
}`

export const DELETE_REMARK_M = gql`mutation ($id: Int!) {
    deleteRemark(id: $id){
        id
    }
}`

export const UPDATE_REMARK_M = gql`mutation ($input: RemarkInput!) {
    updateRemark(input: $input){
        id
        title
        remarks{
            comment
            min_average
            max_average
            advanced
            alternative_remarks{
                improving
                dis_improving
            }
        }
        classes
    }
}`
export const CREATE_ROLE_M = gql`mutation ($input: RoleInput!) {
    createRole(input: $input){
        id
        name
    }
}`

export const DELETE_ROLE_M = gql`mutation ($input: DeleteRoleInput!) {
    deleteRole(input: $input){
        id
    }
}`

export const UPDATE_ROLE_M = gql`mutation ($input: RoleInput!) {
    updateRole(input: $input){
        id
        name
    }
}`
export const ASSIGN_PERMISSIONS_TO_ROLE_M = gql`mutation ($input: PermissionInput!) {
    assignPermissionToRole(input: $input){
        id
        name
        resource
    }
}`
export const ACTIVATE_USER_M = gql`mutation ($id: Int!,$status: Boolean!) {
    activateUser(id: $id, status: $status)
}
`
export const RESEND_USER_CREDENTIALS_M = gql`mutation ($id: Int!) {
    resendCredentials(id: $id)
}`
export const CREATE_USER_M = gql`mutation ($input: UserInput!) {
    createUser(input: $input){
        id
        first_name
        last_name
        active
        email
        phone
        photo
        role
        teacher
        user_type
        teacher_subjects{
            id
            class_id
            subjects
            abilities
        }
    }
}`
export const UPDATE_USER_M = gql`mutation ($input: UserInput!) {
    updateUser(input: $input){
        id
        first_name
        last_name
        active
        email
        phone
        photo
        role
        teacher
        user_type
        teacher_subjects{
            id
            class_id
            subjects
            abilities
        }
    }
}`
export const DELETE_USER_M = gql`mutation ($id: Int!) {
    deleteUser(id: $id){
        id
    }
}`
export const CREATE_CALENDAR_EVENT_M = gql`mutation ($input: CalendarInput!) {
    createCalendarEvent(input: $input){
        id
        title
        start
        end
        all_day
        included_note
        excluded_note
        included_classes
        excluded_classes
        individual_students
        students{
            id
            name
            photo
        }
        notify
        type
        extra_dates{
            date
        }
        notification_channels
        extendedProps{
            calendar
        }
        processing
        failed
    }
}`
export const UPDATE_CALENDAR_EVENT_M = gql`mutation ($input: CalendarInput!) {
    updateCalendarEvent(input: $input){
        id
        title
        start
        end
        all_day
        included_note
        excluded_note
        included_classes
        excluded_classes
        notify
        type
        individual_students
        students{
            id
            name
            photo
        }
        extra_dates{
            date
        }
        notification_channels
        extendedProps{
            calendar
        }
        processing
        failed
    }
}`
export const DELETE_CALENDAR_EVENT_M = gql`mutation ($id: Int!) {
    deleteCalendarEvent(id: $id){
        id
    }
}`

export const CREATE_STUDENT_M = gql`  mutation ($input: StudentInput!) {
    createStudent(input: $input){
        id
        student_id
        first_name
        last_name
        middle_name
        address
        interests
        gender
        dob
        age
        class_id
        photo
        created_at
        status
        parents{
            id
            first_name
            last_name
            phone
            relation
        }
    }
}`
export const DELETE_STUDENT_M = gql`mutation ($id: Int!) {
    deleteStudent(id: $id){
        id
    }
}`
export const DELETE_PARENT_STUDENT_M = gql`mutation ($id: Int!) {
    deleteParentStudent(id: $id){
        id
    }
}`

export const UPDATE_STUDENT_M = gql`mutation ($input: StudentInput!) {
    updateStudent(input: $input){
        id
        student_id
        first_name
        last_name
        middle_name
        address
        interests
        gender
        dob
        age
        class_id
        photo
        created_at
        status
        parents{
            id
            parent_id
            first_name
            last_name
            phone
            relation
        }
    }
}`
export const TOGGLE_STUDENT_STATUS_M = gql`mutation ($input: StudentStatusInput!) {
    toggleStudentStatus(input: $input){
        id
        status
    }
}`
export const DELETE_TEACHER_SUBJECT_M = gql`mutation ($id: Int!) {
    deleteTeacherSubject(id: $id){
        id
    }
}`

export const DELETE_ASSESSMENT_SETTINGS = gql`mutation ($ids: [Int!]!) {
    deleteAssessmentSettings(ids: $ids)
}`

export const CREATE_ASSESSMENT_SETTING = gql`mutation ($input: AssessmentSettingInput!) {
    createAssessmentSetting(input: $input){
        id
        class
        subject
        class_score_percentage
        exam_score_percentage
    }
}`
export const CREATE_FEE_SETTING_M = gql`mutation ($input: FeeSettingInput!) {
    createFeeSetting(input: $input){
        id
        title
        status
        term_id
        term
        academic_year
        academic_year_id
        amount
        items{
            amount
            title
        }
        due_date
        display_date
        display_name
        classes
    }
}`
export const UPDATE_FEE_SETTING_M = gql`mutation ($input: FeeSettingInput!) {
    updateFeeSetting(input: $input){
        id
        title
        status
        term_id
        term
        academic_year
        academic_year_id
        amount
        items{
            amount
            title
        }
        due_date
        display_date
        display_name
        classes
    }
}`
export const DELETE_FEE_SETTING_M = gql`mutation ($id: Int!) {
    deleteFeeSetting(id: $id){
        id
    }
}`
export const SAVE_BANK_M = gql`mutation ($input: BankInput!) {
    saveBank(input: $input){
        id
        account_name
        account_number
        bank_code
    }
}`
export const UPDATE_ADDRESS_M = gql`mutation ($input: AddressInput!) {
    updateAddress(input: $input){
        id
        country_id
        region_id
        city_id
        town
        box_address
    }
}`
export const UPDATE_SCHOOL_M = gql`mutation ($input: SchoolInput!) {
    updateSchool(input: $input){
        name
        about
        alias
        new_alias
        email
        logo
        receipt_size
        phone
        slogan
        chat_access
        id
        date_established
        apply_charges
        accept_online_payments
        active_sms
        trial_ends
        verified
        creche_classes
    }
}`
export const UPLOAD_SCHOOL_LOGO_M = gql`mutation ($input: SchoolLogoInput!) {
    uploadSchoolLogo(input: $input)
}`
export const UPLOAD_USER_PHOTO_M = gql`mutation ($input: UserPhotoInput!) {
    updateUserPhoto(input: $input)
}`
export const UPDATE_USER_INFO_M = gql`mutation ($input: UpdateUserInput!) {
    updateUserInfo(input: $input){
        id
        first_name
        last_name
        active
        email
        phone
        photo
    }
}`
export const REMOVE_USER_PHOTO_M = gql`mutation{
    removeUserPhoto
}`
export const UPDATE_USER_PASSWORD_M = gql`mutation ($input: UserPasswordInput!) {
    updateUserPassword(input: $input)
}`
export const INCLUDE_EXCLUDE_STUDENTS_M = gql`mutation ($input: IncludeExcludeInput!) {
    includeExcludeStudents(input: $input)
}`
export const RECORD_FEE_PAYMENT_M = gql`mutation ($input: FeePaymentInput!) {
    recordFeePayment(input: $input){
        student_id
        fee_setting_id
        receipt_id
        fee_id
        is_full_payment
        first_name
        last_name
        middle_name
        photo
        fee_payment_date
        amount_paid
        title
        code
        last_payment_class
        total_amount_paid
        pending_amount
        payer_name
        payer_phone
        payment_mode
    }
}`
export const UPDATE_FEE_M = gql`mutation ($input: FeeInput!) {
    updateFee(input: $input){
        id
        amount
        full_name
        photo
        student_id
        fee_setting_id
        payer_name
        payer_phone
        date
        actual_date
        payment_type
        receipt_id
        payment_mode
        payment_class
    }
}`

export const DELETE_FEE_PAYMENT_M = gql`mutation ($ids: [Int!]!) {
    deleteFees(ids: $ids)
}`

export const CREATE_RECURRING_FEE_SETTING_M = gql`mutation ($input: RecurringFeeSettingInput!) {
    createRecurringFeeSetting(input: $input){
        id
        title
        amount
        classes
        date
    }
}`
export const UPDATE_RECURRING_FEE_SETTING_M = gql`mutation ($input: RecurringFeeSettingInput!) {
    updateRecurringFeeSetting(input: $input){
        id
        title
        amount
        classes
        date
    }
}`
export const DELETE_RECURRING_FEE_SETTING_M = gql`mutation ($id: Int!) {
    deleteRecurringFeeSetting(id: $id)
}`

export const RECORD_FAILED_PAYMENTS_M = gql`mutation ($input: FailedPaymentInput!) {
    recordFailedPayment(input: $input)
}`
export const GENERATE_RECEIPTS_M = gql`mutation ($input: GenerateReceiptInput!) {
    generateReceipt(input: $input)
}`
export const GENERATE_ARREARS_LIST_M = gql`mutation ($input: ArrearsListInput!) {
    generateArrearsList(input: $input)
}`
export const GENERATE_PROJECTION_LIST_M = gql`mutation ($academic_year_id: Int,$term_id: Int,$fee_setting_id: Int!) {
    generateFeeProjection(academic_year_id:$academic_year_id,term_id:$term_id,fee_setting_id: $fee_setting_id){
        name
        path
    }
}`
export const SEND_ARREARS_NOTIFICATION_M = gql`mutation ($input: ArrearsNotificationInput!) {
    sendArrearsNotification(input: $input)
}`
export const GENERATE_STUDENT_ID_CARDS_M = gql`mutation ($class_id:Int,$students:[Int!]) {
    generateIdCard(class_id: $class_id,students: $students)
}`

export const CREATE_SIGNATURE_M = gql`mutation ($input: SignatureInput!) {
    createSignature(input: $input){
        id
        title
        document_type
        signed_by
        name
        signature
        updated_at
        classes
    }
}`
export const UPDATE_SIGNATURE_M = gql`mutation ($input: SignatureInput!) {
    updateSignature(input: $input){
        id
        title
        document_type
        signed_by
        name
        signature
        updated_at
        classes
    }
}`
export const DELETE_SIGNATURE_M = gql`mutation ($id: Int!) {
    deleteSignature(id: $id){
        id
    }
}`
export const GENERATE_ASSESSMENT_SHEET_M = gql`mutation ($input: TerminalAssessmentInput!) {
    generateAssessmentSheet(input:$input)
}
`
export const CREATE_OTHER_INCOME_M = gql`mutation ($input: OtherIncomeInput!) {
    createOtherIncome(input: $input){
        id
        title
        income_category_id
        date
        amount
        details
    }
}`
export const UPDATE_OTHER_INCOME_M = gql`mutation ($input: OtherIncomeInput!) {
    updateOtherIncome(input: $input){
        id
        title
        income_category_id
        date
        amount
        details
    }
}`
export const DELETE_OTHER_INCOME_M = gql`mutation ($id: Int!) {
    deleteOtherIncome(id: $id){
        id
    }
}`

export const CREATE_EXPENSE_M = gql`mutation ($input: ExpenseInput!) {
    createExpense(input: $input){
        id
        expenses_category_id
        title
        date
        amount
        details
    }
}`
export const UPDATE_EXPENSE_M = gql`mutation ($input: ExpenseInput!) {
    updateExpense(input: $input){
        id
        expenses_category_id
        title
        date
        amount
        details
    }
}`
export const DELETE_EXPENSE_M = gql`mutation ($id: Int!) {
    deleteExpense(id: $id){
        id
    }
}`
export const ACTIVATE_SMS_ACCOUNT_M = gql`mutation{
    activateSmsAccount
}`
export const SAVE_HUBTEL_CLIENT_CREDENTIALS_M = gql`mutation ($client_id: String,$client_secret:String,$password:String!,$sender_name: String!){
    saveHubtelClientCredentials(password: $password,client_id: $client_id,client_secret: $client_secret,sender_name:$sender_name)
}`

export const UPDATE_SENDER_ADDRESS_M = gql`mutation($alias: String!){
    updateSenderAddress(alias: $alias)
}`
export const REGISTER_SIGNUP_ATTEMPT_M = gql`mutation($input: SignupAttemptInput!){
    registerAttempt(input: $input){
        id
    }
}`
export const RUN_AUTO_PROMOTE_ROUTINE_M = gql`mutation($status: Boolean!,$classes:[Int!]!){
    runAutoPromoteRoutine(status: $status,classes:$classes)
}`

export const COMPLETE_ACADEMIC_YEAR_M = gql`mutation($reverse: Boolean!,$academic_year_id: Int!,$current_term: Boolean!){
    completeAcademicYear(reverse: $reverse,academic_year_id:$academic_year_id,current_term:$current_term)
}`
export const INITIATE_SUBSCRIPTION_M = gql`mutation($pricing_id: Int!,$total_students: Int!,$duration: Int!){
    initiateSubscription(pricing_id: $pricing_id,total_students:$total_students,duration: $duration)
}`
export const UPGRADE_SUBSCRIPTION_M = gql`mutation($total_students: Int!){
    upgradeSubscription(total_students:$total_students)
}`
export const SEND_TEST_SMS_M = gql`mutation{
    sendTestSms
}`
export const APPROVE_STUDENTS_RESULTS_M = gql`
    mutation ($input: ResultsApprovalInput!){
        approveResults(input: $input)
    }`
export const SEND_DAILY_REPORT_M = gql`
    mutation ($input: DailyReportInput!){
        sendDailyReports(input: $input)
    }`

export const SET_ACTIVE_TERM_M = gql`
    mutation ($id: Int!){
        setActiveTerm(id: $id)
    }`

export const SET_ACTIVE_ACCOUNT_M = gql`
    mutation ($id: Int!,$remember_me: Boolean!){
        setActiveAccount(id: $id,remember_me: $remember_me){
            user{
                id
                first_name
                last_name
                active
                email
                phone
                photo
            }
            school{
                name
                about
                alias
                new_alias
                date_established
                email
                logo
                receipt_size
                phone
                chat_access
                slogan
                id
                verified
                accept_online_payments
                modules
                apply_charges
                active_sms
                trial_ends
                creche_classes
                lng
                lat
            }
            current_academic_year{
                name
                term
                tag
                ay_term_id
            }
            role
            permissions{
                name
                resource
            }
            token{
                access_token
                refresh_token
            }
            multiple_accounts
        }
    }`
